import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DetailsListEditable, {
  IDetailsListBasicProps,
} from "../components/DetailsListEditable";
import {
  Ladeliste,
  TypingsOptions,
  useGetLadelisteOptionsQuery,
} from "../gql/graphql";
import {
  Dropdown,
  IColumn,
  IComboBox,
  IComboBoxOption,
  IDropdownOption,
  TextField,
} from "@fluentui/react";
import { FloatNumberTextBox } from "../components/FloatNumberTextBox";
import { ComboColumn } from "../components/ComboColumn";
import { ZeitColumn } from "../components/ZeitColumn";

interface ILadeListeTableProps
  extends Omit<IDetailsListBasicProps<Ladeliste>, "columns" | "onClickRow"> {
  onIntervallChange: (intervall: number) => void;
  disabled?: boolean;
  intervall: number;
  einbaudatum: Date;
  belagssortenoptions?: TypingsOptions[];
  onBelagssorteChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onTransportChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onMengeChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onLKWPlusFuhreChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onLKWArtChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
  onLadezeitChange: (
    ladelisteelement?: Ladeliste,
    newValue?: string | undefined,
    index?: number
  ) => void;
}

interface ILadeListeColumninnerProps {
  onChange: (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  item?: Ladeliste;
  disabled?: boolean;
  data?: TypingsOptions[];
}

function TransportColumn(props: ILadeListeColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return show ? (
    <TextField
      onBlur={changeShow}
      onChange={onChange}
      disabled={props.disabled}
      value={item?.transport ?? ""}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      {item?.transport ?? ""}
    </div>
  );
}

function MengeColumn(props: ILadeListeColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  let showWarning =
    item?.menge === "" ||
    item?.menge === "0" ||
    item?.menge === 0 ||
    item?.menge === null ||
    item?.menge === undefined;

  return show ? (
    <TextField
      onBlur={changeShow}
      onChange={onChange}
      type="number"
      onGetErrorMessage={
        showWarning
          ? (str) => {
              return (
                <p style={{ textWrap: "initial", color: "red" }}>
                  Feld braucht einen Wert.
                </p>
              );
            }
          : undefined
      }
      min={0}
      validateOnLoad={showWarning}
      validateOnFocusOut={showWarning}
      validateOnFocusIn={showWarning}
      disabled={props.disabled}
      value={item?.menge + "" ?? ""}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      <p> {item?.menge ?? ""}</p>
      {showWarning ? (
        <>
          <p style={{ textWrap: "initial", color: "red" }}>
            Feld braucht einen Wert.
          </p>
        </>
      ) : null}
    </div>
  );
}

function TranspLKWPlusFuhreColumn(props: ILadeListeColumninnerProps) {
  const [show, setShow] = useState(false);
  const { onChange, item } = props;
  const changeShow = useCallback(() => {
    setShow(!show);
  }, [show]);
  let showError = !(Number(String(item?.lkw_Plus_Fuhre) ?? "0") > 1);
  return show ? (
    <FloatNumberTextBox
      onBlur={changeShow}
      onChange={onChange}
      disabled={props.disabled}
      value={String(item?.lkw_Plus_Fuhre) ?? ""}
      errorMessage="Falscher Wert!"
      showWarning={showError}
    />
  ) : (
    <div style={{ width: "100%", height: "100%" }} onClick={changeShow}>
      <p>{String(item?.lkw_Plus_Fuhre ?? "")}</p>
      {showError ? (
        <p style={{ textWrap: "initial", color: "red" }}>Falscher Wert!</p>
      ) : null}
    </div>
  );
}

export function LadeListeTable(props: ILadeListeTableProps) {
  const [selectedIntervall] = useState(props.intervall ?? 15);
  const {
    onTransportChange,
    onLKWPlusFuhreChange,
    onLKWArtChange,
    onBelagssorteChange,
    onMengeChange,
    onLadezeitChange,
    ...innerProps
  } = props;
  const { loading, data: optionsData } = useGetLadelisteOptionsQuery();
  const [llliste, setLlliste] = useState(props.data);

  const onRenderLadezeit = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      return (
        <ZeitColumn
          value={String(item?.ladezeit)}
          anchor={new Date(props.einbaudatum)}
          increments={selectedIntervall}
          disabled={props.disabled}
          onChange={(v) => {
            onLadezeitChange(item, v, index);
          }}
        />
      );
    },
    [onLadezeitChange, props.einbaudatum, selectedIntervall, props]
  );

  const onRenderTransport = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onTransportChange(item, val, index);
      };
      return (
        <TransportColumn
          disabled={props.disabled}
          onChange={onChange}
          item={item}
        />
      );
    },
    [onTransportChange, props]
  );

  const onRenderMenge = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onMengeChange(item, val, index);
      };
      return (
        <MengeColumn
          disabled={props.disabled}
          onChange={onChange}
          item={item}
        />
      );
    },
    [onMengeChange, props]
  );

  const onRenderLKWArt = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onLKWArtChange(item, String(option?.key), index);
      };
      let showWarning =
      (item?.lkw_Art === null ||
      item?.lkw_Art === undefined ||
      item.lkw_Art === "") &&  (item?.lkw_art_nr === null ||
        item?.lkw_art_nr === undefined );

      return (
        <ComboColumn<Ladeliste>
          data={optionsData?.LKW ?? []}
          optionskeyfield="lkw_art_nr"
          field="lkw_Art"
          errorMessage="Bitte wähle ein LKW Art aus."
          showError={showWarning}
          disabled={props.disabled}
          onChange={onChange}
          optionsCreator={(pr) => ({
            key: pr.option_nr ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [optionsData?.LKW, onLKWArtChange, props]
  );
  useEffect(() => {
    if (props.data !== llliste) {
      setLlliste(props.data);
    }
  }, [llliste, props.data]);

  const onRenderLKWPlusFuhre = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        e: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
      ) => {
        onLKWPlusFuhreChange(item, val, index);
      };
      return (
        <TranspLKWPlusFuhreColumn
          disabled={props.disabled}
          onChange={onChange}
          item={item}
        />
      );
    },
    [onLKWPlusFuhreChange, props]
  );

  const onRenderBelagssorte = useCallback(
    (
      item?: Ladeliste,
      index?: number | undefined,
      column?: IColumn | undefined
    ) => {
      const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        i?: number | undefined,
        value?: string | undefined
      ) => {
        onBelagssorteChange(item, String(option?.text), index);
      };
      let showWarning =
        item?.belags_Sorte === null ||
        item?.belags_Sorte === undefined ||
        item.belags_Sorte === "";
      return (
        <ComboColumn<Ladeliste>
          data={props.belagssortenoptions ?? []}
          field="belags_Sorte"
          onChange={onChange}
          errorMessage="Bitte wähle eine Belagssorte aus."
          showError={showWarning}
          disabled={props.disabled}
          optionsCreator={(pr) => ({
            key: pr.option_value ?? "",
            text: pr.option_value ?? "",
          })}
          item={item}
        />
      );
    },
    [onBelagssorteChange, props.belagssortenoptions, props]
  );

  const llcolumns: IColumn[] = useMemo(
    () => [
      {
        key: "lkw_Plus_Fuhre",
        name: "LKW Plus Fuhre",
        fieldName: "lkw_Plus_Fuhre",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: onRenderLKWPlusFuhre,
      },
      {
        key: "lkw_Art",
        name: "LKW Art",
        fieldName: "lkw_Art",
        minWidth: 150,
        maxWidth: 350,
        isResizable: true,
        onRender: onRenderLKWArt,
      },
      {
        key: "belags_Sorte",
        name: "Belagssorte",
        fieldName: "belags_Sorte",
        minWidth: 100,
        maxWidth: 350,
        isResizable: true,
        onRender: onRenderBelagssorte,
      },
      {
        key: "transport",
        name: "Transport",
        fieldName: "transport",
        minWidth: 100,
        maxWidth: 350,
        isResizable: true,
        onRender: onRenderTransport,
      },
      {
        key: "menge",
        name: "Menge",
        fieldName: "menge",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        onRender: onRenderMenge,
      },
      {
        key: "ladezeit",
        name: "Ladezeit",
        fieldName: "ladezeit",
        minWidth: 140,
        isResizable: true,
        onRender: onRenderLadezeit,
        onRenderHeader: (item) => (
          <>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: 10 }}>Ladezeit</p>
              <Dropdown
                options={["15", "30", "45", "60", "75", "90", "105", "120"].map(
                  (pr) => ({ key: pr, text: pr })
                )}
                disabled={props.disabled}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption<any> | undefined,
                  index?: number | undefined
                ) => {
                  if (option) {
                    props.onIntervallChange(Number(option.text));
                  }
                }}
              />
            </div>
          </>
        ),
      },
    ],
    [
      props,
      onRenderTransport,
      onRenderLKWArt,
      onRenderLKWPlusFuhre,
      onRenderBelagssorte,
      onRenderMenge,
      onRenderLadezeit,
    ]
  );

  const lkwsundfuhren = useMemo(() => {
    let lkw_Plus_Fuhre_arr = Array.from(
      new Set(props.data.map((pr) => pr.lkw_Plus_Fuhre + ""))
    );
    let lkws = Array.from(
      new Set(lkw_Plus_Fuhre_arr.map((pr) => pr.split(".")[0]))
    ).length;
    let fuhren = lkw_Plus_Fuhre_arr.map((pr) => pr.split(".")[1]).length;
    return `Total LKW ${lkws} / Total Fuhren ${fuhren}`;
  }, [props]);

  return !loading ? (
    <>
      <DetailsListEditable<Ladeliste>
        disabled={props.disabled}
        columns={llcolumns}
        {...innerProps}
      />
      {lkwsundfuhren}
    </>
  ) : (
    <></>
  );
}
